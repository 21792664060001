import React from 'react';
import logo from './static/logo.jpeg'; // Tell webpack this JS file uses this image
// import logo2 from './static/logo2.png'; // Tell webpack this JS file uses this image
import Grid from '@material-ui/core/Grid';

function Header() {
    return <Grid container direction="row" justify="center" alignItems="center">
    <div><img src={logo} alt="Logo" /></div>
    <div><h1>emaid- Memory Wall</h1></div>
    </Grid>;

}

export default Header;