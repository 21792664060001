import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';
// import axios from "axios";
// import { saveAs } from 'file-saver';
import Header from "./Header";
import Grid from '@material-ui/core/Grid';
import CircularIndeterminate from "./Spinner";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function App(){


    const classes = useStyles();
    const [canvas, setCanvas] = useState();  
    const [query, setQuery] = useState();
    const [loading,setLoading] = useState(false);
    const [title,setTitle] = useState("my-memaid");
    const [pictureTitle,setPictureTitle] = useState("my-picture");

    const initCanvas = () => {
      const width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
      const height = (window.innerHeight > 0) ? window.innerHeight : window.screen.height;
      return(new fabric.Canvas('canvas', {
        height: height*1,
        width: width*1,
        // backgroundImage: "https://i.postimg.cc/zBHHk4Zy/memorywall.png"
      })
    )
    }

    
    useEffect(() => {
      setCanvas(initCanvas());
      // setBackground();
    }, []); 
    
    const handleChangeTextPictureTitle =  (val) => {
      setPictureTitle(val);
      console.log(val);
    };


    const handleChangeTextTitle =  (val) => {
      setTitle(val);
      console.log(val);
    };

    const handleChange =  (val) => {
      setQuery(val);
      console.log(val);
    };

    const retrieveImage = async (query) => {

      // const formData = new FormData();
      // formData.append('query',{query});
      
      // https://cors-anywhere.herokuapp.com/
      // const url = `https://cors-anywhere.herokuapp.com/https://imret-ahfrfoiwia-ew.a.run.app/imagesearch?query=${query}`;
      // const url = `https://imret-ahfrfoiwia-ew.a.run.app/?query=${query}`;
      // const config = {
      //   onUploadProgress: (progressEvent) => {
      //     let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      //     console.log(percentCompleted);
      //   }
      // }
      
      // const url = 'http://mclip-66621670.us-east-1.elb.amazonaws.com/generate';

 
      // const body = {caption : query};
      // const res = await axios.post(url,body,config);
      const res = await fetch('http://mclip-66621670.us-east-1.elb.amazonaws.com/generate', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "caption": "A wild forest adventure by Studio Ghibli" })
  });
      console.log(res)
      console.log(res['data']);
      return res['data']['url']
    }

    // const check_health = () => {
    //   // const url = "https://imret-ahfrfoiwia-ew.a.run.app/health";
    //   const url = "https://serene-springs-73093.herokuapp.com/health";
    //   axios.get(url).then(res => {
    //     console.log(res);
    //   })
    //   .catch(error => {})
    // }

    const searchImage = async (canvas,query) => {
      console.log(canvas);
      const image_url = await retrieveImage(query);
      addImage(canvas,image_url);
      console.log(canvas);
      return setLoading(false)
    }

    const addImage = (canvas,image_url) => {
      // var myImg = `data:image/png;base64,${image_data}`;
      fabric.Image.fromURL(image_url, function(oImg) {
        // scale image down, and flip it, before adding it onto canvas
        oImg.scale(0.5).set('flipX',true);
        canvas.add(oImg);
      },{ crossOrigin: 'anonymous'});
    }

    // const removeObject = (canvas) => {
    //   var objects = canvas.getObjects();
    //   for(var i = 0; i < objects.length; i++){
    //     //console.log(objects[i]);     
    //     canvas.remove(objects[i]);
    // }
    // canvas.renderAll();
    // }

    const setBackground = () =>{
      // var myImg = `data:image/png;base64,${bgImage}`;
      fabric.Image.fromURL("https://i.ibb.co/QdPgVmc/memorywall.png",function(oimg) {
      canvas.setBackgroundImage(oimg,canvas.renderAll.bind(canvas), {
        scaleX : canvas.width /oimg.width,
        scaleY : canvas.height /oimg.height,
        backgroundImageOpacity: 0.5,
        // should the image be resized to fit the container?
        backgroundImageStretch: false
    });
  })
  }

  const deleteSelectedObjectsFromCanvas = (canvas) =>{
    var selection = canvas.getActiveObject();
    console.log(selection);
    if (selection.type === 'activeSelection') {
        selection.forEachObject(function(element) {
            console.log(element);
            canvas.remove(element); 
        });
    }
    else{
        canvas.remove(selection);
    }
    canvas.discardActiveObject();
    canvas.requestRenderAll();
}

  const addTextPicture = (text,canvas) => {
    var selection = canvas.getActiveObject();
    var fabricImage;
    if (selection == null ) {
      return 0
    }
    console.log(selection);
    var items = selection._objects; 
    
    if (typeof items !== 'undefined') {
      fabricImage = items[0];
      fabricImage.left= selection.left;
      fabricImage.top = selection.top;
      fabricImage.setCoords();
      canvas.remove(selection);
    }
    else {
      fabricImage = selection;
    }

    var textBox = new fabric.Textbox(text, {
      top: fabricImage.lineCoords.bl['y'],
      left: fabricImage.lineCoords.bl['x'] +20,
      width : fabricImage.lineCoords.br['x'] - fabricImage.lineCoords.bl['x'] -40,
      fontSize: 16,
      textAlign: "center",
      fill: 'black',
      backgroundColor : '#E3AF31',
      cornerStyle : "Circle",
      editable: true

  });
  var group = new fabric.Group([fabricImage,textBox],{left: fabricImage.oCoords.bl['x'], top : fabricImage.oCoords.tl['y']});
  canvas.add(group);
  canvas.remove(selection);
  }


    const addTextTitle = (text) => {
    var textBox = new fabric.Textbox(text, {
      width: canvas.width,
      top: 0,
      left: 0,
      fontSize: 42,
      textAlign: "center",
      fill            : '#666',
      backgroundColor : 'White',
      selectable : false,
    }
    

  );
  canvas.add(textBox);
  }



  const saveMemoryWall = () => {
    console.log(JSON.stringify(canvas));
    localStorage.setItem('canvas', JSON.stringify(canvas));
    
  }

  const loadCanvas = () => {
    var retrievedObject = JSON.parse(localStorage.getItem('canvas'));
    console.log('canvas: ', retrievedObject);
    canvas.loadFromJSON(retrievedObject, canvas.renderAll.bind(canvas));
    

  }
  const downloadImage = async ()  => {
    var image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
    var link = document.createElement('a');
    link.download = "my-image.png";
    link.href = image;
    link.click();
  }


  // const downloadImage = async () => {


  //   const blob = await fetch(canvas.toDataURL()).then((res) => res.blob());
  //   // var image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
  //   saveAs(blob, title +".png");
  // }
    
    // const removeBackground = async () => {
    //   var selection = canvas.getActiveObject();
    //   console.log(selection);

    //   const blob = await fetch(selection["_element"]['currentSrc']).then((res) => res.blob());
    //   var formData = new FormData();
    //   formData.append("file", blob);

    //   const url ="https://bgrm-ahfrfoiwia-ew.a.run.app/predict"
    //   const res = await axios({
    //     method: 'post',
    //     url: url,
    //     data: formData,
    //     headers: {'Content-Type': 'multipart/form-data', "Access-Control-Allow-Origin": "*"}
    //     })
    //     // todo decide whether to automatically delete non background removed image.
    //   canvas.remove(selection);
    //   console.log(res)
    //   const image_data = res['data']['file'];
    //   addImage(canvas,image_data);
    //   return setLoading(false)
    // }

    return(
      <div className={classes.root}>
      <Header></Header>
<Grid container direction="row" justify="center" alignItems="center">
<TextField id="standard-basic" label="Write an image caption" placeholder = "Image caption"  
        onKeyPress= {(e) => {
          if (e.key === 'Enter') {
            console.log('Enter key pressed');
            setLoading(true);
            searchImage(canvas,query);          }
        }
      }
        onChange = {(e)=> handleChange(e.target.value)}/>

<TextField id="textBox" label="Add a title" placeholder = "title"  
        onKeyPress= {(e) => {
          if (e.key === 'Enter') {
            console.log('Enter key pressed');
            addTextTitle(title);          }
        }
      }
        onChange = {(e)=> handleChangeTextTitle(e.target.value)}/>

<TextField id="textBoxPicture" label="Add caption" placeholder = "Add caption"  
        onKeyPress= {(e) => {
          if (e.key === 'Enter') {
            console.log('Enter key pressed');
            addTextPicture(pictureTitle,canvas);          }
        }
      }
        onChange = {(e)=> handleChangeTextPictureTitle(e.target.value)}/>
{/* <input type = "text" placeholder = "search for image" onChange = {(e)=> handleChange(e.target.value)} /> */}


        </Grid>
        <br></br>
        <Grid container direction="row" justify="center" alignItems="center" >
        {/* <Button
        button variant="contained"
       onClick={(event) => {
        event.preventDefault();
        setLoading(true);
        removeBackground();
      }}>
        Remove background
        </Button> */}

         <Button
        button variant="contained"
       onClick={(event) => {
        event.preventDefault();
        setBackground();
      }}>
        Sett Bakgrunnen
        </Button> 
        <Button
        button variant="contained"
       onClick={(event) => {
        event.preventDefault();
        deleteSelectedObjectsFromCanvas(canvas);
      }}>
        fjern objektet
        </Button>
        
        </Grid>
        
        <Grid container direction="column" justify="center" alignItems="center"> 
        {(loading === true) && (<CircularIndeterminate></CircularIndeterminate>)}
        </Grid> 
        <canvas id="canvas" /> 
        <Grid container direction="column" justify="center" alignItems="center">

        <Button
        button variant="contained"
       onClick={(event) => {
        event.preventDefault();
        downloadImage();
      }}>
        download memorywall
        </Button>
        <Button
        button variant="contained"
       onClick={(event) => {
        event.preventDefault();
        saveMemoryWall();
      }}>
        save memorywall
        </Button>
        <Button
        button variant="contained"
       onClick={(event) => {
        event.preventDefault();
        loadCanvas();
      }}>
        load memorywall
      </Button>
        </Grid>
      </div>
    );
  }
